import { Box } from '@material-ui/core'
import { ErrorOutlineRounded } from '@mui/icons-material'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUserInfo } from '../auth'
import { useImpersonation } from '@/contexts/impersonation'
import { setupClientsIdentify } from '@/lib/clients'
import { useEnv } from '@/lib/env'
import EmptyState from '@components/common/EmptyState'
import { Loader } from '@components/common/Loader'
import { getInstitutionModules } from '@hooks/fetch/institutionsServiceData'
import { getTenant } from '@hooks/fetch/tenant'
import { Institution } from '@models/Institution'
import { Jurisdiction } from '@models/Jurisdiction'
import { Role } from '@models/Role'

interface InstitutionContext {
  selectedInstitution: Institution | null
  selectedJurisdiction: Jurisdiction | null
  selectJurisdiction(maybeJurisdiction: Jurisdiction | null): void
  selectInstitution(maybeInstitution: Institution | null): void
  jurisdictionLoading: boolean
  setJurisdictionLoading(loading: boolean): void
  initialized: boolean
  setInitialized: (initialized: boolean) => void
}

const institutionContext = React.createContext<InstitutionContext>(
  null as unknown as InstitutionContext
)

export const InstitutionContextProvider = props => {
  const [selectedJurisdiction, setSelectedJurisdiction] =
    useState<Jurisdiction | null>(null)

  const [selectedInstitution, setSelectedInstitution] =
    useState<Institution | null>(null)

  const [jurisdictionLoading, setJurisdictionLoading] = useState(false)
  const [initialized, setInitialized] = useState(false)

  return (
    <institutionContext.Provider
      value={{
        selectedJurisdiction,
        selectJurisdiction: setSelectedJurisdiction,
        selectedInstitution,
        selectInstitution: setSelectedInstitution,
        jurisdictionLoading,
        setJurisdictionLoading,
        initialized,
        setInitialized,
      }}
      {...props}
    />
  )
}

export function useInstitutionContext() {
  return useContext(institutionContext)
}

export const institutionContextGuard = (Component: React.ComponentType) => {
  return function WrappedComponent(props) {
    const { t } = useTranslation(['institution-context'])
    const { amplitudeApiKey } = useEnv()
    const { getAxiosInstance } = useImpersonation()
    const axiosInstance = getAxiosInstance()
    const user = useUserInfo()
    const router = useRouter()
    const [storedInstitutionId, setStoredInstitutionId] = useState(
      localStorage.getItem('selectedInstitution')
    )

    const { selectedJurisdiction, selectedInstitution, jurisdictionLoading } =
      useInstitutionContext()

    const institutionChanged =
      selectedInstitution && selectedInstitution?.id !== storedInstitutionId

    if (user.hasRole(Role.TUTOR) || user.hasRole(Role.TUTOR_MASTER)) {
      router.push('/docentes')
    }

    if (institutionChanged && selectedInstitution) {
      setStoredInstitutionId(selectedInstitution.id)
      localStorage.setItem('selectedInstitution', selectedInstitution.id)
    }

    useEffect(() => {
      const identify = async () => {
        const tenant = await getTenant(
          selectedInstitution?.id ?? '',
          axiosInstance
        )
        setupClientsIdentify({
          user,
          customParams: {
            tenant_id: tenant?.id ?? '',
            tenant_name: tenant?.name ?? '',
            jurisdiction_id: selectedInstitution?.jurisdictionId ?? '',
            institution_id: selectedInstitution?.id ?? '',
            institution_name: selectedInstitution?.name ?? '',
            jurisdiction_name: selectedInstitution?.jurisdiction?.name ?? '',
            is_student: user.hasRole(Role.STUDENT),
            is_teacher: user.hasRole(Role.TEACHER),
            rol: user.firstRole as string,
          },
          envs: {
            amplitudeApiKey,
          },
        })
      }
      if (user?.sub && selectedInstitution?.id && storedInstitutionId) {
        identify()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedInstitutionId])

    if (!selectedJurisdiction) {
      return jurisdictionLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="80%"
          width="100%"
        >
          <Loader />
        </Box>
      ) : (
        <Box mt={5}>
          <EmptyState
            icon={ErrorOutlineRounded}
            title={t('institution-context:empty-state.title')}
            subtitle={t('institution-context:empty-state.subtitle')}
          />
        </Box>
      )
    }

    return <Component {...props} />
  }
}

export const curricularModuleGuard = (Component: React.ComponentType) => {
  return function WrappedComponent(props) {
    const [curricularModule, setCurricularModule] = useState<boolean>(false)
    const { selectedInstitution } = useInstitutionContext()
    const { getAxiosInstance } = useImpersonation()

    const isStudent = useUserInfo().hasRole(Role.STUDENT)
    const axiosInstance = getAxiosInstance()

    getInstitutionModules(selectedInstitution?.id ?? '', axiosInstance).then(
      institution => {
        const moduleFound = institution?.modules.some(m => m.id === 'curricula')
        setCurricularModule(moduleFound ?? false)
      }
    )

    if (!curricularModule || !isStudent) return null

    return <Component {...props} />
  }
}
