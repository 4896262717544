import { initialize } from 'launchdarkly-react-client-sdk'
import { hotjar } from 'react-hotjar'
import { useUserInfo } from './auth'
import { LaunchdarklyIdentify } from './featureFlag'
import {
  analyticsIdentify,
  AnalyticsIdentifyParams,
  analyticsInitialize,
  clearAnalyticsIdentify,
} from '@/lib/analytics'
import { ClientSideEnvironment } from '@/lib/env'
import { sentryInitialize, sentryIdentify, clearSentryUser } from '@/lib/sentry'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SetupClients
  extends Pick<
    ClientSideEnvironment,
    | 'amplitudeApiKey'
    | 'sentryDsn'
    | 'nodeEnv'
    | 'hotjar'
    | 'launchDarklyClientId'
  > {}

interface SetupIdentifyClients extends AnalyticsIdentifyParams {
  envs: Partial<
    Pick<
      ClientSideEnvironment,
      | 'amplitudeApiKey'
      | 'sentryDsn'
      | 'nodeEnv'
      | 'hotjar'
      | 'launchDarklyClientId'
    >
  >
}

export const setupClients = ({
  sentryDsn,
  amplitudeApiKey,
  nodeEnv,
  hotjar: hotjarConfig,
  launchDarklyClientId,
}: SetupClients) => {
  if (nodeEnv === 'production') {
    if (sentryDsn) {
      sentryInitialize({
        sentryDsn,
        env: nodeEnv,
      })
    }
  }

  if (amplitudeApiKey) {
    analyticsInitialize({
      amplitudeApiKey,
    })
  }

  if (launchDarklyClientId) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = useUserInfo()
    initialize(launchDarklyClientId, user)
  }

  if (hotjarConfig?.id) {
    hotjar.initialize(hotjarConfig.id, hotjarConfig.snippetVersion)
  }
}

export const setupClientsIdentify = ({
  user,
  customParams,
  envs: { amplitudeApiKey, nodeEnv, sentryDsn, launchDarklyClientId },
}: SetupIdentifyClients) => {
  if (amplitudeApiKey) {
    analyticsIdentify({
      user,
      customParams,
    })
  }

  if (nodeEnv === 'production') {
    if (sentryDsn) {
      sentryIdentify({ user })
    }
  }

  if (hotjar.initialized()) {
    hotjar.identify(user.sub, {
      email: user.email ?? undefined,
      username: user.name ?? undefined,
      is_student: customParams?.is_student,
      is_teacher: customParams?.is_teacher,
    })
  }

  if (launchDarklyClientId) {
    const { identify } = LaunchdarklyIdentify()
    const institution = {
      id: customParams?.institution_id ?? '',
      name: customParams?.institution_name ?? '',
    }
    identify(institution)
  }
}

export const clearClientsIdentify = () => {
  clearAnalyticsIdentify()
  clearSentryUser()
}
