import * as Sentry from '@sentry/nextjs'
import { User } from '@models/User'
export const sentry = Sentry

interface SentryInitializeParams {
  sentryDsn: string
  env: string
}

interface SetSentryUser {
  user: User
}

export const sentryInitialize = ({
  sentryDsn,
  env,
}: SentryInitializeParams): void => {
  sentry.init({
    dsn: sentryDsn,
    environment: env,
    tracesSampleRate: 0,
  })
}

export const sentryIdentify = ({ user }: SetSentryUser) => {
  sentry.setUser({
    id: user.sub,
    email: user.email ?? 'unknown',
    username: user.name ?? 'unknown',
  })
}

export const clearSentryUser = () => sentry.setUser(null)
